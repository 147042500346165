<template>
  <v-card>
    <v-toolbar flat>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>Mantenimientos</v-toolbar-title>

      <v-spacer></v-spacer>

       <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon  v-bind="attrs"  v-on="on" dark color="indigo" @click="dialogExit=true"> 
              <v-icon dark>logout</v-icon>
            </v-btn>
          </template>
          <span>Salir de aplicacion</span>
      </v-tooltip>

    

      <template v-slot:extension>
        <v-tabs v-model="tabs" fixed-tabs>

          <v-tabs-slider></v-tabs-slider>

           <v-tab href="#mobile-tabs-5-0" class="primary--text">
            <v-icon>event_note</v-icon>
          </v-tab>

            <v-tab href="#mobile-tabs-5-1" class="primary--text">
            <v-icon>build_circle</v-icon>
          </v-tab>

           <v-tab href="#mobile-tabs-5-2" class="primary--text">
            <v-icon>history</v-icon>
          </v-tab>



          <v-tab href="#mobile-tabs-5-3" class="primary--text">
            <v-icon>roofing</v-icon>
          </v-tab>


          <v-tab href="#mobile-tabs-5-4" class="primary--text">
            <v-icon>format_align_center</v-icon>
          </v-tab>

          <v-tab href="#mobile-tabs-5-5" class="primary--text">
            <v-icon>category</v-icon>
          </v-tab>


        
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tabs">

        <v-tab-item :value="'mobile-tabs-5-0'">
        <v-card flat>
          <v-card-text>
           <Actual />
          </v-card-text>
        </v-card>
      </v-tab-item>

       <v-tab-item :value="'mobile-tabs-5-1'">
        <v-card flat>
          <v-card-text>
          <Correctivo />
          </v-card-text>
        </v-card>
      </v-tab-item>


       <v-tab-item :value="'mobile-tabs-5-2'">
        <v-card flat>
          <v-card-text>
           <Historico />
          </v-card-text>
        </v-card>
      </v-tab-item>




       <v-tab-item :value="'mobile-tabs-5-3'">
        <v-card flat>
          <v-card-text>
             <Taller />
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item :value="'mobile-tabs-5-4'">
        <v-card flat>
          <v-card-text>
           <Tipo />
          </v-card-text>
        </v-card>
      </v-tab-item>



      <v-tab-item :value="'mobile-tabs-5-5'">
        <v-card flat>
          <v-card-text>
            <Item />
          </v-card-text>
        </v-card>
      </v-tab-item>

      
    </v-tabs-items>

     <v-dialog v-model="dialogExit"  hide-overlay
                     persistent
                     width="250">
                        <v-card>
                          <v-card-title>Salir de aplicacion</v-card-title>
                          <v-card-text>Deseas salir?</v-card-text>
                          <v-card-actions class="justify-space-around">
                            <v-btn @click="dialogExit=false" text>Cancelar</v-btn>
                          <v-btn @click="cerrar()" dark color="indigo">Salir</v-btn></v-card-actions>
                        </v-card>
                </v-dialog>

  </v-card>
</template>

<script>

import { mapActions } from "vuex";

import Actual from '../mtto/VisorActual.vue';
import Taller from '../mtto/Taller.vue';
import Tipo from '../mtto/Tipo.vue';
import Item from '../mtto/Item.vue';
import Historico from '../mtto/Historico.vue';
import Correctivo from '../mtto/ManttoCorrectivo.vue';


  export default {
      components:{
          Taller, Tipo, Item, Actual, Historico, Correctivo
         },
    data () {
      return {
        dialogExit:false,
        tabs: null,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      }
    },
     methods:{

        ...mapActions(['salir']),

         cerrar(){
              this.dialogExit=false;
              localStorage.removeItem('usuario');
              localStorage.removeItem('token');
              
              this.salir();
            },



    }


  }
</script>