<template>

<v-card>
    <v-card-title>
       <span>Mantenimientos Correctivos</span>

        <v-divider  class="mx-4" inset vertical></v-divider>

         <v-flex md2 lg2 mx-4 pt-6>
                        <v-select v-model="id_pais_activo" dense 
                        outlined :items="PaisesArray" @change="listar()"  label="Pais"></v-select>
         </v-flex>


          <v-flex  mx-md-3 mx-lg-4  mx-sm-2 mx-xs-1 mx-1>
                <v-btn dark color="success" @click="dialogInicio=true">
                  <v-icon>date_range</v-icon>
                </v-btn>
            </v-flex>


        <v-btn dark color="indigo" class="mx-5" @click="listar()"><v-icon>autorenew</v-icon></v-btn>

         <v-spacer></v-spacer>

         <v-text-field class="mx-5"
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
        ></v-text-field>


        <v-btn color="info" dark class="mb-2"  @click="dialog=true">
              <v-icon dark>add</v-icon>
            </v-btn>


    </v-card-title>


<v-layout>
  <v-data-table
    :headers="headers"
    :items="ArregloMantenimientos"
     sort-by="placa"
    :search="search"
    class="elevation-1"
  >
  
      

    <template v-slot:[`item.id_vehiculo_nombre`]="{ item }">
       <span>{{item.id_vehiculo_mant.nombre}}</span>
       <img  :src="CambiarImgUrl(item.id_vehiculo_mant.icono)" v-bind:alt="item.icono" 
         style="width: 30px; height: 30px"/>
     </template>


      <template v-slot:[`item.placa`]="{ item }">
       <span>{{item.id_vehiculo_mant.placa}}</span>
     </template>

    
      <template v-slot:[`item.id_vehiculo_flota`]="{ item }"> 
       <span>{{item.id_vehiculo_mant.flota}}</span>
     </template>


     <template v-slot:[`item.id_tipo_mant`]="{ item }"> 
       <span>{{item.id_tipo_mant.nombre_tipo}}</span>
         <v-icon class="ma-2" color="indigo"  @click="abrirDetalle(item)">preview
    </v-icon>

     </template>

      <template v-slot:[`item.fecha_mant`]="{ item }"> 
       <span>{{ConvertirFecha(item.fecha_mant)}} </span>
     </template>

     <template v-slot:[`item.fecha_fin`]="{ item }"> 
       <span>{{ConvertirFecha(item.fecha_fin)}} </span>
     </template>

   


     

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
      <v-icon small @click="deleteItem(item)">delete</v-icon>
    </template>

    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>
  </v-data-table>






      <v-dialog v-model="dialog" max-width="780px">

          <v-card>


             <v-toolbar dark color="fondo1">
              <v-toolbar-title>
                 <span class="headline">{{ formTitle }}</span>
              </v-toolbar-title>
                 <v-spacer></v-spacer>
                 <v-icon @click="dialog=false" dark>close</v-icon>
            </v-toolbar>


            <v-card-text>
              <v-container>
                <v-row>

                   

                  <v-col cols="12" sm="5" md="5" xl="5" lg="5">
                        <v-select
                            v-model="editedItem.id_vehiculo_mant"
                            :items="UnidadesArray"
                            prepend-icon="time_to_leave"
                            label="Vehiculo"
                            :rules="[rules.required]"
                            :error-messages="errorMessages"
                           
                          
                        ></v-select>
                  </v-col>



                  <v-col  cols="12" sm="4" md="3">
                  

                    <v-select
                            v-model="editedItem.id_tipo_mant"
                            :items="TipoArray"
                            label="Tipo mantenimiento"
                            :rules="[rules.required]"
                            :error-messages="errorMessages"
                        ></v-select>

                  </v-col>


                   <v-col  cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-select
                                v-model="editedItem.id_taller_mant"
                                :items="itemsTalleres"
                                label="Taller"
                                :rules="[rules.required]"
                                :error-messages="errorMessages"
                            ></v-select>

                            </v-col>




                  <v-col cols="12" sm="4" md="4">
                   

                     <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            v-model="editedItem.fecha_mant"
                            label="Fecha Inicio"
                            prepend-icon="today"
                            v-bind="attrs"
                            v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="editedItem.fecha_mant"
                            no-title
                            @input="menu1 = false"
                        ></v-date-picker>
                        </v-menu>

                  </v-col>


                   <v-col cols="12" sm="4" md="4">
                   

                     <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            v-model="editedItem.fecha_fin"
                            label="Fecha Fin"
                            prepend-icon="today"
                            v-bind="attrs"
                            v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="editedItem.fecha_fin"
                            no-title
                            @input="menu2 = false"
                        ></v-date-picker>
                        </v-menu>

                  </v-col>



                   <v-col  cols="12" sm="6" md="3">
                        <v-text-field v-model="editedItem.costo_mant" prepend-icon="paid" label="costo"></v-text-field>
                      </v-col>

                      <v-col  cols="12" sm="11" md="11">
                        <v-text-field v-model="editedItem.observacion_mant" prepend-icon="description" label="Observacion"></v-text-field>
                      </v-col>


              
                    <v-col  cols="12" xs="12" sm="12" md="12" lg="12">

                      <v-list shaped style="height:200px;overflow-y:auto;width:80%;">
                        
                              <v-list-item-group multiple>
                                <template v-for="(item, i) in ItemArray">
                            
                            
                                  <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

                                  <v-list-item v-else :key="item.value" :value="item.value" active-class="deep-purple--text text--accent-4">
                              
                              
                                    <template v-slot:default="{ active }">
                                      <v-list-item-content>
                                        <v-list-item-title v-text="item.text"></v-list-item-title>
                                      </v-list-item-content>

                                      <v-list-item-action>
                                        <v-checkbox
                                          :id="item.value"
                                          :input-value="active"
                                          v-model="ChekedItems"
                                          :value="item.value"
                                          color="deep-purple accent-4"
                                        ></v-checkbox>
                                      </v-list-item-action>
                                    </template>
                                  </v-list-item>
                              
                                </template>
                              </v-list-item-group>
                              <span v-if="ItemArray.length < 1"> no hay items asociados</span>
                              
                            
                            </v-list>

                    </v-col>


                
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="success" dark @click="save">Guardar</v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>


        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Deseas deshabilitar Mantenimiento?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>




     <v-dialog v-model="dialogDetalle" max-width="500px">

     <v-card>

           <v-toolbar dark color="fondo1">
              <v-toolbar-title>
                 <span class="headline">Detalle mantenimiento</span>
              </v-toolbar-title>
                 <v-spacer></v-spacer>
                 <v-icon @click="dialogDetalle=false" dark>close</v-icon>
            </v-toolbar>

        <v-card-text>

          <v-simple-table light> 
              <template v-slot:default>
              <tbody>

                <tr >
                <td><v-icon small left>time_to_leave</v-icon>Unidad</td>
                <td>{{detalle.unidad}}</td>
              </tr>

               <tr >
                <td><v-icon small left>commute</v-icon>Flota</td>
                <td>{{detalle.flota}}</td>
              </tr>


               <tr >
                <td><v-icon small left>list_alt</v-icon>Tipo mantenimiento</td>
                <td>{{detalle.tipo_mant}} / {{ detalle.clasificacion}}</td>
              </tr>


              <tr>
                <td><v-icon small left>paid</v-icon>Costo</td>
                <td>{{parseFloat(detalle.costo).toFixed(2)}} </td>
              </tr>

              <tr>
                <td><v-icon small left>apartment</v-icon>taller</td>
                <td>{{detalle.taller}}</td>
              </tr>

              <tr>
                <td><v-icon small left>today</v-icon>Inicio/Fin</td>
                <td>{{detalle.fecha_mant}}  -> {{detalle.fecha_fin}}</td>
              </tr>

             

               <tr>
                <td colspan="2"><v-icon small left>description</v-icon>
                {{detalle.observacion}}</td>
              </tr>

            </tbody>
            </template>

            </v-simple-table>

            <v-divider></v-divider>

            <div>
              
              <v-chip v-for="(item, index) in detalle.items"  :key="index" class="ma-2" color="primary" outlined>
                <v-icon left>build_circle</v-icon>
                 {{ renderizarItem(item.ref_item)}}
              </v-chip>
            </div>


        </v-card-text>
      </v-card>

  </v-dialog>
     

</v-layout>


     
<v-dialog v-model="dialogInicio" persistent max-width="280">
      <v-card>
         <v-toolbar flat color="fondo1" class="text-center">
        <v-toolbar-title class="white--text  text-center"><strong>Historial por fechas</strong></v-toolbar-title>
      </v-toolbar>

        <v-card-text>



           <v-flex>
      
          

                    <v-menu
                      ref="menuFecha1"
                      v-model="menuFecha1"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                  
                      offset-y
                      min-width="290px"
                    >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fecha_desde"
                              label="Fecha inicio"
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="fecha_desde" locale="es"
                          no-title  @input="menuFecha1 = false" scrollable>
                          </v-date-picker>
                    </v-menu>
              </v-flex>

          <v-flex>
                 <v-menu
                  ref="menuFecha2"
                  v-model="menuFecha2"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
              
                  offset-y
                  min-width="290px"
                >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fecha_hasta"
                          label="Fecha fin"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fecha_hasta" locale="es"
                       no-title  @input="menuFecha2 = false" scrollable>
                      </v-date-picker>
                </v-menu>

              </v-flex>

           
                <v-flex justify-center d-flex>
                  
                      <v-btn color="primary" dark @click="listar()" style="margin-right:10px;">Generar</v-btn>
                    <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>

               </v-flex>
   
        </v-card-text>
       
      </v-card>
</v-dialog>
    
    </v-card>
</template>



<script>


import axios from 'axios';
import { mapMutations, mapState } from "vuex";

  export default {
    data: () => ({
      search:'',
      Tipos:[],
      TipoArray:[],
      ArrayVehiculos:[],
      UnidadesArray:[],
      nombre_flota:'',
     
      ArregloMantenimientos:[],
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Vehiculo', value: 'id_vehiculo_nombre' },
        { text: 'Placa', value: 'placa' },
        { text: 'Tipo Mantenimiento', value: 'id_tipo_mant' },
        { text: 'Clasificacion', value: 'clasificacion' },
        { text: 'Taller', value: 'id_taller_mant.nombre_taller' },
        { text: 'Fecha inicio', value: 'fecha_mant'},
        { text: 'Fecha fin', value: 'fecha_fin' },
        { text: 'Costo', value: 'costo_mant' },
        { text: 'Observacion', value: 'observacion_mant' },

        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    
      editedIndex: -1,
      editedItem: {
        id_vehiculo_mant:'',
        id_tipo_mant: '',
        id_taller_mant:'',
        items_realizados:[],
        fecha_anterior_mant:new Date().toISOString().substr(0, 10),
        odometro_anterior_mant: 0,
        kms_realizar: 0,
        remanente:0,
        odometro_actual:0,
        status:1,
        clasificacion:'Correctivo',
        observacion_mant:'',
        fecha_mant:'',
        fecha_fin:'',
        costo_mant:'',
        id_pais_mant:'',
        activo:1
      },
      defaultItem: {
        id_vehiculo_mant:'',
        id_tipo_mant: 0,
        id_taller_mant: 0,
        fecha_anterior_mant:new Date().toISOString().substr(0, 10),
        odometro_anterior_mant: 0,
        kms_realizar: 0,
        remanente:0,
        odometro_actual:0,
        status:1,
        clasificacion:'Correctivo',
        observacion_mant:'',
        fecha_mant:'',
        fecha_fin:'',
        costo_mant:'',
        id_pais_mant:'',
        activo:1
      },

       errorMessages:'',
       rules: {
          numerico:(value)=>value > 0 || "valor incorrecto",
          min3Chars: (value) => value.length >= 3 || "Min. 3 characters",
          required: (value) => !!value || "campo Requerido.",
          email: (value) => {
                              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                              return pattern.test(value) || "Invalid e-mail.";
                           },
       
       }, 
       menu1:false,
       date: new Date().toISOString().substr(0, 10),

        
       dialogInicio:false,

        menuFecha1:false,
        fecha_desde:new Date().toISOString().substr(0, 10),
        menuFecha2:false,
        fecha_hasta:new Date().toISOString().substr(0, 10),
       
      

       menu2:false,
       time: null ,
       id_avl:'',

       menu3:false,

       ArrayTalleres:[],
       itemsTalleres:[],
       dialogRegistrar:false,


       itemRegistrar:{
         unidad:'',
         tipo_mantenimiento:'',
         id_taller_mant:'',
         observacion_mant:'',
         fecha_mant:'',
         costo_mant:'',
       },

       dialogDetalle:false,
       detalle:{},

       mantenimiento_siguiente:'',
       ArrayItemMantenimento:[],
       ItemArray:[],
       ChekedItems:[],

       PaisesArray:[],
       id_pais_activo:'',


    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Mantto Correctivo' : 'Edicion Mantto Correctivo'
      },

        ...mapState(['usuario', 'token']),

    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize();
       this.listarItems();
     
    },

    methods: {

       ...mapMutations(['mostrarLoading','ocultarLoading']),

      initialize () {
        this.ArregloMantenimientos = [];
        this.listarPaises();


        let hora=new Date().getHours();
        if(hora <10){hora='0'+hora;}
        
        let minutos=new Date().getMinutes();
        if(minutos <10){minutos='0'+minutos;}

        this.time=hora+":"+minutos;
      },


      listarPaises() {

         let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

         let RespuestaPaises=[];

          axios.get('paises',  config
          ).then(function(response){
             console.log(response);
             RespuestaPaises=response.data;

             RespuestaPaises.map(function(x){
                  me.PaisesArray.push({text:x.nombre_pais, value:x._id})
                });

             me.id_pais_activo=me.PaisesArray[0].value;

             me.ocultarLoading();

              me.listar();
              me.listarVehiculos();
              me.listarTipos();
            
              
          }).catch(function(error){
            console.log(error);
          });

         


        },




      listar(){

              this.ArregloMantenimientos=[];

               this.mostrarLoading({titulo:'Accediendo a datos'});
              let config={headers:{token:this.token}};
              let me=this;
              axios.post('mantenimientos-mc',
                      {   
                      'desde':this.fecha_desde,
                      'hasta':this.fecha_hasta,
                      'id_pais_mant':this.id_pais_activo
                    },
                  config
                  ).then(function(response){
                      console.log(response);
                      me.ArregloMantenimientos=response.data;
                      me.dialogInicio=false;
                     me.ocultarLoading();
                  }).catch(function(error){ console.log(error); });

        },

      listarVehiculos(){

                    this.ArrayVehiculos=[];
                 
                 

                      this.mostrarLoading({titulo:'Accediendo a datos'});
                      let config={headers:{token:this.token}};
                      let me=this;
                    axios.get(`vehiculos/${this.id_pais_activo}`,
                        config
                        ).then(function(response){
                            console.log(response);
                            me.ArrayVehiculos=response.data;

                            me.ArrayVehiculos.map(function(x){
                                        me.UnidadesArray.push({'text':x.nombre, 'value':x._id});
                            });

                          me.ocultarLoading();
                        }).catch(function(error){ console.log(error); });

      },

      listarTipos(){

              this.TipoArray=[];
              this.Tipos=[];

              this.mostrarLoading({titulo:'Accediendo a datos'});
              let config={headers:{token:this.token}};
              let me=this;
              axios.get(`tipos/${this.id_pais_activo}`,
                  config
                  ).then(function(response){
                      console.log(response);

                      me.Tipos=response.data;

                            me.Tipos.map(function(x){
                               if(x.grupo=='Correctivo'){
                                me.TipoArray.push({'text':x.nombre_tipo, 'value':x._id});
                               }
                            });

                            me.listarTalleres();
    
                     me.ocultarLoading();
                  }).catch(function(error){ console.log(error); });
      },

  

      listarTalleres(){
            this.ArrayTalleres=[];
            this.itemsTalleres=[];

              this.mostrarLoading({titulo:'Accediendo a datos'});
              let config={headers:{token:this.token}};
              let me=this;
              axios.get(`talleres/${this.id_pais_activo}`,
                config
                  ).then(function(response){
                      console.log(response);
                      me.ArrayTalleres=response.data;

                      me.ArrayTalleres.map(function(x){
                        me.itemsTalleres.push({text:x.nombre_taller, value:x._id});
                      });
                   me.ocultarLoading();
                  }).catch(function(error){ console.log(error); });

      },

      listarItems(){
         this.ArrayItemMantenimento=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`items`,  config
                    ).then(function(response){
                      
                        me.ArrayItemMantenimento=response.data;  
                        me.ArrayItemMantenimento.map(function(x){
                          me.ItemArray.push({text:x.nombre_item, value:x._id});

                        });

                        me.ocultarLoading();

                          console.log(me.ArrayItemMantenimento);
                    }).catch(function(error){ console.log(error); });
      },





      editItem (item) {
        this.editedIndex = this.ArregloMantenimientos.indexOf(item)
        this.editedItem = Object.assign({}, item)

        
          //setamos valor de vehiculo en edicion
         this.editedItem.id_vehiculo_mant=item.id_vehiculo_mant._id;

         //convertimos las fecha
         this.editedItem.fecha_mant=new Date(item.fecha_mant).toISOString().substr(0, 10);


          let texto='';

        if((item.fecha_fin!='')&&(item.fecha_fin!=null)){
          texto=new Date(item.fecha_fin).toISOString().substr(0, 10);
        }


         //convertimos las fecha
         this.editedItem.fecha_fin=texto;




        //seteamo valor tipo mantenimiento
         this.editedItem.id_tipo_mant=item.id_tipo_mant._id;


       
          this.ChekedItems=[];
        for(let obvjeto of  item.items_realizados){
          // console.log('iteramos:'+obvjeto.ref_item);
              this.ChekedItems.push(obvjeto.ref_item);
        }


        

        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.ArregloMantenimientos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
       // this.ArregloMantenimientos.splice(this.editedIndex, 1)

                this.mostrarLoading({titulo:'Accediendo a datos'});

                let id=this.editedItem._id;
                 let config={headers:{token:this.token}};

                    axios.put(`mantenimientos/${id}`,  {'activo':0}, config
                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });

        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
            //edicion de mantenimiento
            

            if((this.editedItem.id_taller_mant!='')&&(this.editedItem.fecha_mant!='')&&(this.editedItem.costo_mant!='')&&(this.ChekedItems.length > 0)
            &&(this.editedItem.id_vehiculo_mant!='') &&(this.editedItem.id_tipo_mant!='')){



                  let fecha_fin='';

                  let insertar=false;

                  console.log('fecha fin:'+this.editedItem.fecha_fin);

                  if((this.editedItem.fecha_fin!='')&&(this.editedItem.fecha_fin!=null)){
                           
                            if((new Date(this.editedItem.fecha_mant+"T00:00:00.000-06:00") <= new Date(this.CalcularFechaHoy()+"T00:00:00.000-06:00"))
                            &&(new Date(this.editedItem.fecha_fin+"T00:00:00.000-06:00") >= new Date(this.CalcularFechaHoy()+"T00:00:00.000-06:00"))){
                              this.ActualizarEstadoVehiculo(this.editedItem.id_vehiculo_mant, 'Taller');
                            }

                              if((new Date(this.editedItem.fecha_mant+"T00:00:00.000-06:00") < new Date(this.CalcularFechaHoy()+"T00:00:00.000-06:00"))
                            &&(new Date(this.editedItem.fecha_fin+"T00:00:00.000-06:00") < new Date(this.CalcularFechaHoy()+"T00:00:00.000-06:00"))){
                              this.ActualizarEstadoVehiculo(this.editedItem.id_vehiculo_mant, 'Activo');
                            }

                             fecha_fin=this.editedItem.fecha_fin+"T00:00:00.000-06:00";


                         insertar=true;
                  }else{

                    if((this.editedItem.fecha_fin==null)||(this.editedItem.fecha_fin=='')){

                        if (confirm('mantto no tiene fecha final, vehiculo apartir de hoy pasara al estado de taller?')) {
                          // Save it!
                           this.ActualizarEstadoVehiculo(this.editedItem.id_vehiculo_mant, 'Taller');
                           insertar=true;
                        } else {
                          // Do nothing!
                            console.log('nada que hacer');
                        }

                       
                    }

                  }//fin si no hay fecha de fin
             


                  console.log('items:'+this.ChekedItems);
                    let separa=this.ChekedItems;
                    separa=separa.toString();
                    separa=separa.split(",");

                    let ArrayEnviarItems=[];

                  for(let i=0; i < separa.length; i++){
                    console.log('datos:ref_item:'+separa[i]);
                    ArrayEnviarItems.push({ref_item:separa[i]});
                  }


              if(insertar==true){

                    let config={headers:{token:this.token}};
                     this.mostrarLoading({titulo:'actualizando'});
                    let id=this.editedItem._id;

                    axios.put(`mantenimientos/${id}`,
                      { 
                            'id_pais_mant': this.id_pais_activo,
                            'id_vehiculo_mant': this.editedItem.id_vehiculo_mant,
                            'id_tipo_mant': this.editedItem.id_tipo_mant,
                            'id_taller_mant': this.editedItem.id_taller_mant,
                            'items_realizados':ArrayEnviarItems,
                            'fecha_anterior_mant': new Date(),
                            'odometro_anterior_mant':0,
                            'kms_realizar':0,
                            'remanente':0,
                            'status':2,
                            'clasificacion':'Correctivo',
                            'observacion_mant': this.editedItem.observacion_mant,
                            'fecha_mant': this.editedItem.fecha_mant+"T00:00:00.000-06:00",
                            'fecha_fin':fecha_fin,
                            'costo_mant':this.editedItem.costo_mant,
                            'activo':this.editedItem.activo

                       },
                       config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                        this.close()
                        this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });

            }///fin de guardar 

             

        }else{
          alert('faltan campos');
        }
        
        
        } else {
         // this.ArregloMantenimientos.push(this.editedItem)
         //nuevo registro


        if((this.editedItem.id_taller_mant!='')&&(this.editedItem.fecha_mant!='')&&(this.editedItem.costo_mant!='')&&(this.ChekedItems.length > 0)
            &&(this.editedItem.id_vehiculo_mant!='') &&(this.editedItem.id_tipo_mant!='')){



                console.log('items:'+this.ChekedItems);
                    let separa=this.ChekedItems;
                    separa=separa.toString();
                    separa=separa.split(",");

                    let ArrayEnviarItems=[];

                  for(let i=0; i < separa.length; i++){
                    console.log('datos:ref_item:'+separa[i]);
                    ArrayEnviarItems.push({ref_item:separa[i]});
                  }


                    let fecha_fin='';

                  let insertar=false;

                  console.log('fecha fin:'+this.editedItem.fecha_fin);

                  if((this.editedItem.fecha_fin!='')&&(this.editedItem.fecha_fin!=null)){
                           
                            if(new Date(this.editedItem.fecha_fin+"T00:00:00.000-06:00") >= new Date(this.CalcularFechaHoy()+"T00:00:00.000-06:00")){
                              this.ActualizarEstadoVehiculo(this.editedItem.id_vehiculo_mant, 'Taller');
                            }


                            if(new Date(this.editedItem.fecha_fin+"T00:00:00.000-06:00") < new Date(this.CalcularFechaHoy()+"T00:00:00.000-06:00")){
                              this.ActualizarEstadoVehiculo(this.editedItem.id_vehiculo_mant, 'Activo');
                            }

                             fecha_fin=this.editedItem.fecha_fin+"T00:00:00.000-06:00";


                         insertar=true;
                  }else{

                    if((this.editedItem.fecha_fin==null)||(this.editedItem.fecha_fin=='')){

                        if (confirm('mantto no tiene fecha final, vehiculo apartir de hoy pasara al estado de taller?')) {
                          // Save it!
                           this.ActualizarEstadoVehiculo( this.editedItem.id_vehiculo_mant, 'Taller');
                           insertar=true;
                        } else {
                          // Do nothing!
                            console.log('nada que hacer');
                        }

                       
                    }

                  }//fin si no hay fecha de fin



        if(insertar==true){
      

                      let config={headers:{token:this.token}};
                        this.mostrarLoading({titulo:'actualizando'});
                      

                        axios.post('nuevo-mantenimiento',
                          { 
                            'id_pais_mant': this.id_pais_activo,
                            'id_vehiculo_mant': this.editedItem.id_vehiculo_mant,
                            'id_tipo_mant': this.editedItem.id_tipo_mant,
                            'id_taller_mant': this.editedItem.id_taller_mant,
                            'items_realizados':ArrayEnviarItems,
                            'fecha_anterior_mant': new Date(),
                            'odometro_anterior_mant':0,
                            'kms_realizar':0,
                            'remanente':0,
                            'status':2,
                            'clasificacion':'Correctivo',
                            'observacion_mant': this.editedItem.observacion_mant,
                            'fecha_mant': this.editedItem.fecha_mant+"T00:00:00.000-06:00",
                            'fecha_fin':fecha_fin,
                            'costo_mant':this.editedItem.costo_mant,
                            'activo':this.editedItem.activo
                          },
                          config

                            ).then(response=>{
                          
                            console.log(response);
                            
                            this.listar();
                            this.close()
                            this.ocultarLoading();

                      }).catch(e=>{
                            console.log(e)
                      });

        }//fin si demos insertar


            

        }else{
          alert('faltan campos');
        }





        }
       
      },


    
      ConvertirFecha(fecha_parametro){

        let texto=new Date(fecha_parametro).toLocaleDateString();

        return(texto);

      },

       abrirDetalle(item){

       
        this.detalle={};
        this.dialogDetalle=true;

        let me=this;

        let odom_actual=0;
        let tipo_odom='';

        if(item.id_vehiculo_mant.tipo_odo_activo=='Software'){

             odom_actual=item.id_vehiculo_mant.odometro_sw;
             tipo_odom='Software';

        }else if(item.id_vehiculo_mant.tipo_odo_activo=='Hardware'){

              odom_actual=item.id_vehiculo_mant.odometro_hw;
               tipo_odom='Hardware';
        }


        let recorrido=parseFloat(odom_actual)-parseFloat(item.odometro_anterior_mant);

        let porcentaje=0;

        if(recorrido > 0){

            porcentaje=recorrido/parseFloat(item.kms_realizar);
            porcentaje=porcentaje*100;

        }

        let texto='';

        if((item.fecha_fin!='')&&(item.fecha_fin!=null)){
          texto=me.ConvertirFecha(item.fecha_fin);
        }



        this.detalle={
                      'unidad':item.id_vehiculo_mant.nombre,
                      'flota':item.id_vehiculo_mant.flota,
                      'tipo_mant':item.id_tipo_mant.nombre_tipo,
                      'odom_anterior':item.odometro_anterior_mant,
                      'odom_actual':odom_actual,
                      'tipo_odom':tipo_odom,
                      'recorrido':recorrido,
                      'km_realizar':item.kms_realizar,
                      'remanente':item.remanente,
                      'porcentaje':porcentaje,
                      'costo':item.costo_mant,
                      'clasificacion':item.clasificacion,

                      'taller':item.id_taller_mant.nombre_taller,
                      'fecha_mant':me.ConvertirFecha(item.fecha_mant),
                      'fecha_fin':texto,
                      'items':item.items_realizados,
                      'observacion':item.observacion_mant
                      };
      
      },


   renderizarItem(_id){
          let texto='';

           this.ArrayItemMantenimento.map(function(x){
                if(x._id==_id){ texto=x.nombre_item;}
           });

           return(texto);

      },


   

      CambiarImgUrl(picture){
            picture=picture.substring(0,picture.length - 5);
            if(picture.slice(-1)!='/'){
                    picture=picture.substring(0,picture.length - 1);
                }
                return (picture+'/E.png');
      },


      getDate(evaluar) {
      let date = new Date(evaluar),
      year = date.getFullYear(),
      month = (date.getMonth() + 1).toString(),
      formatedMonth = (month.length === 1) ? ("0" + month) : month,
      day = date.getDate().toString(),
      formatedDay = (day.length === 1) ? ("0" + day) : day,
      hour = date.getHours().toString(),
      formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
      minute = date.getMinutes().toString(),
      formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
      second = date.getSeconds().toString(),
      formatedSecond = (second.length === 1) ? ("0" + second) : second;
    return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
    },




     ActualizarEstadoVehiculo(_id, estado){

          let config={headers:{token:this.token}};

               this.mostrarLoading({titulo:'Accediendo a datos'});

               console.log('actualizamos el estado a taller');

                    axios.put(`vehiculo/${_id}`,
                      {'estado':estado},
                      config
                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });


    },

      CalcularFechaHoy(){
          let fecha=new Date();
          let mes=fecha.getMonth()+1;
          if(mes<10)mes='0'+mes;
          let dia=fecha.getDate();
          if(dia<10)dia='0'+dia;
          let textofecha=fecha.getFullYear()+'-'+mes+'-'+dia;

          return(textofecha);
        },






    },
  }
</script>