<template>
  <v-card>
    <v-card-title>
      <span>Historial</span>
         <v-divider class="mx-4" inset vertical></v-divider>

        <v-flex md2 lg2 mx-4 pt-5>
                        <v-select v-model="id_pais_activo" dense 
                        outlined :items="PaisesArray" @change="listar()"  label="Pais"></v-select>
         </v-flex>
   
   

      <v-spacer></v-spacer>

      <vue-excel-xlsx v-show="data_excel.length >0"
              :data="data_excel"
              :columns="column_excel"
              :filename="'Historial'"
              :sheetname="'hoja1'">
              <v-btn color="info">
                  <v-icon dark>get_app</v-icon>
              </v-btn>
            
              </vue-excel-xlsx>

      <v-flex  mx-md-3 mx-lg-4  mx-sm-2 mx-xs-1 mx-1>
                <v-btn dark color="success" @click="dialogInicio=true">
                  <v-icon>date_range</v-icon>
                </v-btn>
            </v-flex>

            

      <v-text-field
        v-model="search"
        append-icon="search"
        label="buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="Historial"
      :search="search"
      sort-by="id_vehiculo_nombre"
    >

     <template v-slot:[`item.id_vehiculo_nombre`]="{ item }">
       <span>{{item.id_vehiculo_mant.nombre}}</span>
     </template>

    
      <template v-slot:[`item.id_vehiculo_flota`]="{ item }"> 
       <span>{{item.id_vehiculo_mant.flota}}</span>
     </template>


     <template v-slot:[`item.id_tipo_mant`]="{ item }"> 
        <v-icon color="indigo" @click="abrirDetalle(item)">preview</v-icon>

       <span>{{item.id_tipo_mant.nombre_tipo}} / {{item.id_tipo_mant.cantidad_km}} km</span>
      
     </template>


     <template v-slot:[`item.id_taller_mant`]="{ item }"> 
       <span>{{item.id_taller_mant.nombre_taller}}</span>
     </template>

     <template v-slot:[`item.fecha_mant`]="{ item }"> 
       <span>{{ConvertirFecha(item.fecha_mant)}} </span>
     </template>

      <template v-slot:[`item.fecha_fin`]="{ item }"> 
       <span v-show="item.fecha_fin!='' && item.fecha_fin!=null">{{ConvertirFecha(item.fecha_fin)}} </span>
     </template>


       <template v-slot:[`item.costo_mant`]="{ item }"> 
       <span>${{item.costo_mant}}</span>
     </template>


     <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
      <v-icon small @click="deleteItem(item)">delete</v-icon>
    </template>

     
    
    </v-data-table>


     <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Deseas deshabilitar Mantenimiento?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete=false">Cancelar</v-btn>
              <v-btn color="primary" text @click="deleteItemConfirm()">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>





     <v-dialog v-model="dialogEditar" persistent max-width="650px">

        <v-card>


             <v-toolbar dark color="fondo1">
              <v-toolbar-title>
                 <span class="headline">Editar Mantenimiento realizado</span>
              </v-toolbar-title>
                 <v-spacer></v-spacer>
                 <v-icon @click="dialogEditar=false" dark>close</v-icon>
            </v-toolbar>


            <v-card-text>
                
                <v-row>

                  <v-col  cols="12" sm="6" md="6">
                    <v-text-field v-model="itemEditar.unidad" prepend-icon="time_to_leave" label="vehiculo" :disabled="true"></v-text-field>
                  </v-col>


                  <v-col  cols="12" sm="6" md="6">
                    <v-text-field v-model="itemEditar.tipo_mantenimiento" prepend-icon="speed" label="Tipo Mantenimiento" :disabled="true"></v-text-field>
                  </v-col>

                  <v-col  cols="12" sm="4" md="4" lg="4" xl="4">
                    <v-select
                            v-model="itemEditar.id_taller_mant"
                            :items="itemsTalleres"
                            label="Taller"
                            :rules="[rules.required]"
                            :error-messages="errorMessages"
                        ></v-select>

                        </v-col>

                   


                  <v-col  cols="12" sm="4" md="4" lg="4" xl="4">
                

                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            v-model="itemEditar.fecha_mant"
                            label="Fecha Mantenimiento"
                            prepend-icon="today"
                            v-bind="attrs"
                            v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="itemEditar.fecha_mant"
                            no-title
                            @input="menu = false"
                        ></v-date-picker>
                        </v-menu>
                  </v-col>



                   <v-col  cols="12" sm="4" md="4" lg="4" xl="4">
                    
                        <v-menu
                            ref="menu4"
                            v-model="menu4"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="itemEditar.fecha_fin"
                                label="Fecha Fin"
                                prepend-icon="today"
                                v-bind="attrs"
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="itemEditar.fecha_fin"
                                no-title
                                @input="menu4 = false"
                            ></v-date-picker>
                            </v-menu>
                      </v-col>





                  <v-col  cols="12" sm="6" md="3">
                    <v-text-field v-model="itemEditar.costo_mant" prepend-icon="paid" label="costo"></v-text-field>
                  </v-col>

                  <v-col  cols="12" sm="9" md="9">
                    <v-text-field v-model="itemEditar.observacion_mant" prepend-icon="description" label="Observacion"></v-text-field>
                  </v-col>


                    <v-col  cols="12" xs="12" sm="12" md="12" lg="12">

                  <v-list shaped style="height:200px;overflow-y:auto;width:80%;">
                    
                          <v-list-item-group multiple>
                            <template v-for="(item, i) in ItemArray">
                        
                        
                              <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

                              <v-list-item v-else :key="item.value" :value="item.value" active-class="deep-purple--text text--accent-4">
                          
                          
                                <template v-slot:default="{ active }">
                                  <v-list-item-content>
                                    <v-list-item-title v-text="item.text"></v-list-item-title>
                                  </v-list-item-content>

                                  <v-list-item-action>
                                    <v-checkbox
                                       :id="item.value"
                                       :input-value="active"
                                       v-model="ChekedItems"
                                       :value="item.value"
                                      color="deep-purple accent-4"
                                    ></v-checkbox>
                                  </v-list-item-action>
                                </template>
                              </v-list-item>
                          
                            </template>
                          </v-list-item-group>
                          <span v-if="ItemArray.length < 1"> no hay items asociados</span>
                          
                        
                        </v-list>

                </v-col>

                </v-row>
            </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="blue" @click="dialogEditar=false">Cancelar</v-btn>
          <v-btn dark color="primary" @click="GuardarRegistroMantenimiento()">Guardar</v-btn>
         
        </v-card-actions>

        </v-card>

    </v-dialog>



     <v-dialog v-model="dialogDetalle" max-width="500px">

     <v-card>

           <v-toolbar dark color="fondo1">
              <v-toolbar-title>
                 <span class="headline">Detalle mantenimiento</span>
              </v-toolbar-title>
                 <v-spacer></v-spacer>
                 <v-icon @click="dialogDetalle=false" dark>close</v-icon>
            </v-toolbar>

        <v-card-text>

          <v-simple-table light> 
              <template v-slot:default>
              <tbody>

                <tr >
                <td><v-icon small left>time_to_leave</v-icon>Unidad</td>
                <td>{{detalle.unidad}}</td>
              </tr>

               <tr >
                <td><v-icon small left>commute</v-icon>Flota</td>
                <td>{{detalle.flota}}</td>
              </tr>


               <tr >
                <td><v-icon small left>list_alt</v-icon>Tipo mantenimiento</td>
                <td>{{detalle.tipo_mant}} / {{ detalle.km_realizar}} km</td>
              </tr>


              <tr>
                <td><v-icon small left>straighten</v-icon>Recorrido</td>
                <td>{{parseFloat(detalle.recorrido).toFixed(2)}} km</td>
              </tr>

              <tr>
                <td><v-icon small left>apartment</v-icon>taller</td>
                <td>{{detalle.taller}}</td>
              </tr>

              <tr>
                <td><v-icon small left>today</v-icon>Fecha</td>
                <td>{{detalle.fecha}}</td>
              </tr>

               <tr>
                <td colspan="2"><v-icon small left>description</v-icon>
                {{detalle.observacion}}</td>
              </tr>

            </tbody>
            </template>

            </v-simple-table>

            <v-divider></v-divider>

            <div>
              
              <v-chip v-for="(item, index) in detalle.items"  :key="index" class="ma-2" color="primary" outlined>
                <v-icon left>build_circle</v-icon>
                 {{ renderizarItem(item.ref_item)}}
              </v-chip>
            </div>


        </v-card-text>
      </v-card>

  </v-dialog>








  
<v-dialog v-model="dialogInicio" persistent max-width="280">
      <v-card>
         <v-toolbar flat color="fondo1" class="text-center">
        <v-toolbar-title class="white--text  text-center"><strong>Historial por fechas</strong></v-toolbar-title>
      </v-toolbar>

        <v-card-text>



           <v-flex>
      
          

                    <v-menu
                      ref="menuFecha1"
                      v-model="menuFecha1"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                  
                      offset-y
                      min-width="290px"
                    >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fecha_desde"
                              label="Fecha inicio"
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="fecha_desde" locale="es"
                          no-title  @input="menuFecha1 = false" scrollable>
                          </v-date-picker>
                    </v-menu>
              </v-flex>

          <v-flex>
                 <v-menu
                  ref="menuFecha2"
                  v-model="menuFecha2"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
              
                  offset-y
                  min-width="290px"
                >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fecha_hasta"
                          label="Fecha fin"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fecha_hasta" locale="es"
                       no-title  @input="menuFecha2 = false" scrollable>
                      </v-date-picker>
                </v-menu>

              </v-flex>

           
                <v-flex justify-center d-flex>
                  
                      <v-btn color="primary" dark @click="listar()" style="margin-right:10px;">Generar</v-btn>
                    <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>

               </v-flex>
   
        </v-card-text>
       
      </v-card>
</v-dialog>

  </v-card>
</template>

<script>


import axios from 'axios';
import { mapMutations, mapState } from "vuex";

  export default {
    data () {
      return {
        search: '',
        headers: [
        { text: 'Vehiculo', value: 'id_vehiculo_nombre' },
        { text: 'Flota', value: 'id_vehiculo_flota' },
        { text: 'Tipo Mantenimiento', value: 'id_tipo_mant' },
        
         { text:  'Clasificacion',   value: 'clasificacion'},

        { text:  'Taller',   value: 'id_taller_mant'},

        { text:  'Observacion',   value: 'observacion_mant'},
        { text:  'Fecha mantto-inicio',   value: 'fecha_mant'},
         { text:  'Fecha mantto-fin',   value: 'fecha_fin'},
        { text:  'costo',   value: 'costo_mant'},

        { text: 'Acciones', value: 'actions', sortable: false },

        ],
        Historial: [],
         editedIndex: -1,
         editedItem: {
              id_vehiculo_mant:'',
              id_tipo_mant: 0,
              id_taller_mant: 0,
              fecha_anterior_mant:new Date().toISOString().substr(0, 10),
              odometro_anterior_mant: 0,
              kms_realizar: 0,
              remanente:0,
              odometro_actual:0,
              status:1,
              observacion_mant:'',
              fecha_mant:'',
              fecha_fin:'',
              costo_mant:'',
              activo:1
           },
          defaultItem: {
              id_vehiculo_mant:'',
              id_tipo_mant: 0,
              id_taller_mant: 0,
              fecha_anterior_mant:new Date().toISOString().substr(0, 10),
              odometro_anterior_mant: 0,
              kms_realizar: 0,
              remanente:0,
              odometro_actual:0,
              status:1,
              observacion_mant:'',
              fecha_mant:'',
              fecha_fin:'',
              costo_mant:'',
              activo:1
         },

       
        dialogDelete:false,
        dialogEditar:false,

        itemEditar:{
         unidad:'',
         tipo_mantenimiento:'',
         id_taller_mant:'',
         observacion_mant:'',
         fecha_mant:'',
         costo_mant:'',
       },

       ArrayTalleres:[],
       itemsTalleres:[],

       errorMessages:'',
       rules: {
          numerico:(value)=>value > 0 || "valor incorrecto",
          min3Chars: (value) => value.length >= 3 || "Min. 3 characters",
          required: (value) => !!value || "campo Requerido.",
          email: (value) => {
                              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                              return pattern.test(value) || "Invalid e-mail.";
                           },
       
       },
       menu: false,
        menu4:false,
       dialogInicio:false,

        menuFecha1:false,
        fecha_desde:new Date().toISOString().substr(0, 10),
        menuFecha2:false,
        fecha_hasta:new Date().toISOString().substr(0, 10),
        date: new Date().toISOString().substr(0, 10),

         data_excel:[],

          column_excel:[ 
                    {label: "Vehiculo",  field: "Vehiculo"},
                    {label: "Flota", field: "Flota"},
                    {label: "Tipo_Mantenimiento", field: "Tipo_Mantenimiento"},

                    {label: "Taller",  field: "Taller"},
                    {label: "Observacion", field: "Observacion"},
                    {label: "Fecha_Mantenimiento-inicio", field: "Fecha_Mantenimiento"},
                    {label: "Fecha_Mantenimiento-fin", field: "Fecha_Fin"},

                    {label: "Costo",  field: "Costo"}
                  
                    ],


          ArrayItemMantenimento:[],
          ItemArray:[],
          ChekedItems:[],

          dialogDetalle:false,
          detalle:{},

          PaisesArray:[],
          id_pais_activo:'',
      

      }
    },
    created () {

      this.listarPaises();
      this.listarItems();
    

       let fecha_actual=new Date();
       fecha_actual.setMonth(0);
       fecha_actual.setDate(1);

        this.fecha_desde=new Date(fecha_actual).toISOString().substr(0, 10);
      
     
    },
    methods:{

       ...mapMutations(['mostrarLoading','ocultarLoading']),


          listarPaises () {

         let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

         let RespuestaPaises=[];

          axios.get('paises',  config
          ).then(function(response){
             console.log(response);
             RespuestaPaises=response.data;

             RespuestaPaises.map(function(x){
                  me.PaisesArray.push({text:x.nombre_pais, value:x._id})
                });

             me.id_pais_activo=me.PaisesArray[0].value;

             me.ocultarLoading();

             me.listar();
             me.listarTalleres();

            
              
          }).catch(function(error){
            console.log(error);
          });

         


           },



        listar(){

              this.Historial=[];
              this.data_excel=[];

               this.mostrarLoading({titulo:'Accediendo a datos'});
               let config={headers:{token:this.token}};
              let me=this;
              axios.post('mantenimientos-historial',
                     {   
                      'desde':this.fecha_desde,
                      'hasta':this.fecha_hasta,
                      'id_pais_mant':this.id_pais_activo
                    },
                  config
                  ).then(function(response){
                      console.log(response);
                      me.Historial=response.data;
                      me.dialogInicio=false;


                       me.Historial.map(function(x){

                         me.data_excel.push({ 
                              'Vehiculo':x.id_vehiculo_mant.nombre,
                              'Flota':x.id_vehiculo_mant.flota,
                              'Tipo_Mantenimiento':x.id_tipo_mant.nombre_tipo,
                              'Taller':x.id_taller_mant.nombre_taller,
                              'Observacion':x.observacion_mant,
                              'Fecha_Mantenimiento':me.ConvertirFecha(x.fecha_mant),
                              'Fecha_Fin':me.ConvertirFecha(x.fecha_fin),
                              'Costo':x.costo_mant,
                              
                              });  


                       });
                       

                      me.ocultarLoading();
                  }).catch(function(error){ console.log(error); });

        },

         listarTalleres(){
            this.ArrayTalleres=[];
            this.itemsTalleres=[];

            this.mostrarLoading({titulo:'Accediendo a datos'});
            let config={headers:{token:this.token}};
              let me=this;
              axios.get(`talleres/${this.id_pais_activo}`,
                config
                  ).then(function(response){
                      console.log(response);
                      me.ArrayTalleres=response.data;

                      me.ArrayTalleres.map(function(x){
                        me.itemsTalleres.push({text:x.nombre_taller, value:x._id});
                      });
                    me.ocultarLoading();
                  }).catch(function(error){ console.log(error); });

      },


        listarItems(){
         this.ArrayItemMantenimento=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`items`,
                    config
                    ).then(function(response){
                      
                        me.ArrayItemMantenimento=response.data;  
                        me.ArrayItemMantenimento.map(function(x){
                          me.ItemArray.push({text:x.nombre_item, value:x._id});

                        });

                        me.ocultarLoading();

                          console.log(me.ArrayItemMantenimento);
                    }).catch(function(error){ console.log(error); });
      },


      ConvertirFecha(fecha_parametro){
          let texto=new Date(fecha_parametro).toLocaleDateString();
          return(texto);

      },

      deleteItem(item){
         this.editedIndex = this.Historial.indexOf(item)
         this.editedItem = Object.assign({}, item)

         this.dialogDelete = true;
      },
       deleteItemConfirm () {

          let config={headers:{token:this.token}};
    
                let id=this.editedItem._id;
                 this.mostrarLoading({titulo:'Accediendo a datos'});

                    axios.put(`mantenimientos/${id}`,
                      {'activo':0},
                      config
                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });

                  this.dialogDelete = false;

       // this.closeDelete()
      },
      
      editItem (item) {

        let texto='';

        if((item.fecha_fin!='')&&(item.fecha_fin!=null)){
          texto=new Date(item.fecha_fin).toISOString().substr(0, 10);
        }

        this.itemEditar={
          _id:item._id,
         id_vehiculo:item.id_vehiculo_mant._id,
         unidad:item.id_vehiculo_mant.nombre,
         tipo_mantenimiento:item.id_tipo_mant.nombre_tipo,
         id_taller_mant:item.id_taller_mant._id,
         observacion_mant:item.observacion_mant,
         fecha_mant:new Date(item.fecha_mant).toISOString().substr(0, 10),
         fecha_fin:texto,
         costo_mant:item.costo_mant
       };

          this.ChekedItems=[];
        for(let obvjeto of  item.items_realizados){
          // console.log('iteramos:'+obvjeto.ref_item);
              this.ChekedItems.push(obvjeto.ref_item);
        }

       this.dialogEditar=true;

      },

      GuardarRegistroMantenimiento(){

        if(this.itemEditar){
            if((this.itemEditar.id_taller_mant!='')&&(this.itemEditar.fecha_mant!='')&&(this.ChekedItems.length >0)){

              
                      let config={headers:{token:this.token}};
                      this.mostrarLoading({titulo:'actualizando'});

                        console.log('items:'+this.ChekedItems);
                        var separa=this.ChekedItems;
                        separa=separa.toString();
                        separa=separa.split(",");

                        let ArrayEnviarItems=[];

                      for(let i=0; i < separa.length; i++){
                        console.log('datos:ref_item:'+separa[i]);
                        ArrayEnviarItems.push({ref_item:separa[i]});
                      }




                               let fecha_fin='';

                              let insertar=false;

                              console.log('fecha fin:'+this.itemEditar.fecha_fin);

                              if((this.itemEditar.fecha_fin!='')&&(this.itemEditar.fecha_fin!=null)){
                                      
                                        if(new Date(this.itemEditar.fecha_fin+"T00:00:00.000-06:00") > new Date(this.CalcularFechaHoy()+"T00:00:00.000-06:00")){
                                          this.ActualizarEstadoVehiculo(this.itemEditar.id_vehiculo, 'Taller');
                                        }

                                         if(new Date(this.itemEditar.fecha_fin+"T00:00:00.000-06:00") < new Date(this.CalcularFechaHoy()+"T00:00:00.000-06:00")){
                                          this.ActualizarEstadoVehiculo(this.itemEditar.id_vehiculo, 'Activo');
                                        }

                                        fecha_fin=this.itemEditar.fecha_fin+"T00:00:00.000-06:00";


                                    insertar=true;
                              }else{

                                if((this.itemEditar.fecha_fin==null)||(this.itemEditar.fecha_fin=='')){

                                    if (confirm('mantto no tiene fecha final, vehiculo apartir de hoy pasara al estado de taller?')) {
                                      // Save it!
                                      this.ActualizarEstadoVehiculo(this.itemEditar.id_vehiculo, 'Taller');
                                      insertar=true;
                                    } else {
                                      // Do nothing!
                                        console.log('nada que hacer');
                                    }

                                  
                                }

                              }



                         if(insertar==true){       


                                    //status 2 es mantenimiento realizado
                                      let id=this.itemEditar._id;

                                      axios.put(`mantenimientos/${id}`,
                                        { 
                                          'id_taller_mant': this.itemEditar.id_taller_mant, 
                                          'observacion_mant': this.itemEditar.observacion_mant,
                                          'fecha_mant': new Date(this.itemEditar.fecha_mant),
                                          'costo_mant':this.itemEditar.costo_mant,
                                          'fecha_fin':fecha_fin,
                                          'items_realizados':ArrayEnviarItems
                                        },
                                        config

                                          ).then(response=>{
                                        
                                          console.log(response);

                                            this.dialogEditar=false;
                                          
                                          this.listar();
                                        
                                          this.ocultarLoading();

                                    }).catch(e=>{
                                          console.log(e)
                                    });


                         }


            }else{
              alert('faltan campos que completar');
            }
        }


      },


      abrirDetalle(item){

       
        this.detalle={};
        this.dialogDetalle=true;

        let me=this;

        let odom_actual=0;
        let tipo_odom='';

        if(item.id_vehiculo_mant.tipo_odo_activo=='Software'){

             odom_actual=item.id_vehiculo_mant.odometro_sw;
             tipo_odom='Software';

        }else if(item.id_vehiculo_mant.tipo_odo_activo=='Hardware'){

              odom_actual=item.id_vehiculo_mant.odometro_hw;
               tipo_odom='Hardware';
        }


        let recorrido=parseFloat(odom_actual)-parseFloat(item.odometro_anterior_mant);

        let porcentaje=0;

        if(recorrido > 0){

            porcentaje=recorrido/parseFloat(item.kms_realizar);
            porcentaje=porcentaje*100;

        }



        this.detalle={
                      'unidad':item.id_vehiculo_mant.nombre,
                      'flota':item.id_vehiculo_mant.flota,
                      'tipo_mant':item.id_tipo_mant.nombre_tipo,
                      'odom_anterior':item.odometro_anterior_mant,
                      'odom_actual':odom_actual,
                      'tipo_odom':tipo_odom,
                      'recorrido':recorrido,
                      'km_realizar':item.kms_realizar,
                      'remanente':item.remanente,
                      'porcentaje':porcentaje,

                      'taller':item.id_taller_mant.nombre_taller,
                      'fecha':me.ConvertirFecha(item.fecha_mant),
                      'items':item.items_realizados,
                      'observacion':item.observacion_mant
                      };
      
      },


      renderizarItem(_id){
          let texto='';

           this.ArrayItemMantenimento.map(function(x){
                if(x._id==_id){ texto=x.nombre_item;}
           });

           return(texto);

      },


      ActualizarEstadoVehiculo(_id, estado){

          let config={headers:{token:this.token}};

               this.mostrarLoading({titulo:'Accediendo a datos'});

               console.log('actualizamos el estado a taller');

                    axios.put(`vehiculo/${_id}`,
                      {'estado':estado},
                      config
                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });


    },

     CalcularFechaHoy(){
          let fecha=new Date();
          let mes=fecha.getMonth()+1;
          if(mes<10)mes='0'+mes;
          let dia=fecha.getDate();
          if(dia<10)dia='0'+dia;
          let textofecha=fecha.getFullYear()+'-'+mes+'-'+dia;

          return(textofecha);
        },


    },
    computed:{

       ...mapState(['usuario', 'token']),

    }
  }
</script>