<template>

<v-card>
    <v-card-title>
       <span> Items para mantenimentos</span>

        <v-divider  class="mx-4" inset vertical></v-divider>

         <v-btn dark color="indigo" class="mx-5" @click="listar()"><v-icon>autorenew</v-icon></v-btn>

         
        <v-spacer></v-spacer>

         <v-text-field class="mx-5"
        v-model="search"
        append-icon="search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>

       <v-btn color="primary" dark class="mb-2" @click="dialog=true">
            <v-icon dark>add</v-icon>
            </v-btn>

    </v-card-title>


  <v-data-table
    :headers="headers"
    :items="ArrayItemMantenimento"
    :search="search"
    sort-by="orden"
    class="elevation-1"
  >
  

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon  small class="mr-2" @click="editItem(item)">edit</v-icon>
      <v-icon small @click="deleteItem(item)">delete</v-icon>
    </template>
    
    <template v-slot:no-data>
      <v-btn color="primary" @click="listar()">Reset</v-btn>
    </template>


      <template v-slot:[`item.activo`]="{ item }">
        <v-icon>
           {{ item.activo==1 ? "done" : "label_off" }}
        </v-icon>
     </template>


  </v-data-table>

      <v-dialog  v-model="dialog" max-width="500px">
         
          <v-card>

            <v-toolbar dark color="fondo1">
              <v-toolbar-title>
                 <span class="headline">{{ formTitle }}</span>
              </v-toolbar-title>
                 <v-spacer></v-spacer>
                 <v-icon @click="dialog=false" dark>close</v-icon>
            </v-toolbar>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="8" md="8">
                    <v-text-field  v-model="editedItem.nombre_item" prepend-icon="label" label="Nombre Item"></v-text-field>
                  </v-col>

                   <v-col cols="12" sm="12" md="12">
                    <v-text-field  v-model="editedItem.descripcion_item" prepend-icon="description" label="Descripcion item"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field  v-model="editedItem.orden"  prepend-icon="tag" label="Orden"></v-text-field>
                  </v-col>

                    <v-col cols="12" sm="6" md="4">

                    <input type="radio" :value="1" v-model="editedItem.activo"> Activo
                    <input type="radio" :value="0" v-model="editedItem.activo"> Inactivo
 
                  </v-col>

                 
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="primary" dark @click="save">Guardar</v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Deseas borrar este item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        </v-card>
</template>


<script>

import axios from 'axios';
import { mapMutations, mapState } from "vuex";



  export default {
    data: () => ({
      search:'',
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Nombre', value: 'nombre_item' },
        { text: 'Descricion', value: 'descripcion_item' },
        { text: 'Orden', value: 'orden' },
        { text: 'Activo', value: 'activo' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      ArrayItemMantenimento: [],
      editedIndex: -1,
      editedItem: {
        nombre_item: '',
        descripcion_item: '',
        orden: 0,
        activo:1
      },
      defaultItem: {
        nombre_item: '',
        descripcion_item: '',
        orden: 0,
        activo:1
      },

     
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo item' : 'Editar item'
      },

       ...mapState(['usuario', 'token']),

    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize();
    },

    methods: {

          ...mapMutations(['mostrarLoading','ocultarLoading']),


      initialize () {
        this.ArrayItemMantenimento = [];
         this.listar();
      },


      listar(){
                  this.ArrayItemMantenimento=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get('items',
                    config
                    ).then(function(response){
                      
                        me.ArrayItemMantenimento=response.data;

                        me.ArrayItemMantenimento.map(function(x){
                            me.editedItem.orden=parseFloat(x.orden)+1;
                        });

                     
                        me.ocultarLoading();

                          console.log(me.ArrayItemMantenimento);
                    }).catch(function(error){ console.log(error); });
      },



      editItem (item) {
        this.editedIndex = this.ArrayItemMantenimento.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.ArrayItemMantenimento.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
     
         let config={headers:{token:this.token}};

          this.mostrarLoading({titulo:'Accediendo a datos'});

          let id=this.editedItem._id;

                    axios.put(`items/${id}`,
                      {'activo':0},
                      config
                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                         this.ocultarLoading();
                        this.closeDelete()

                  }).catch(e=>{
                        console.log(e)
                  });


       
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
            //editar item
        

                if((this.editedItem.nombre_item!='')&&(this.editedItem.orden!=0)){

                    let config={headers:{token:this.token}};
                    this.mostrarLoading({titulo:'actualizando'});
                    let id=this.editedItem._id;

                     axios.put(`items/${id}`,
                      { 

                        'nombre_item': this.editedItem.nombre_item,
                        'descripcion_item': this.editedItem.descripcion_item,
                        'orden': this.editedItem.orden,
                        'activo': this.editedItem.activo
                       },
                     config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                         this.ocultarLoading();
                          this.close()

                  }).catch(e=>{
                        console.log(e)
                  });

                }else{
                    alert('debes completar todos los  campos');
                }

        } else {
            //guaradr item
         // this.ArrayItemMantenimento.push(this.editedItem)
       
       
            //guardar item


                if((this.editedItem.nombre_item!='')&&(this.editedItem.orden!=0)){

                    let config={headers:{token:this.token}};
                   this.mostrarLoading({titulo:'actualizando'});


                   
                    axios.post('nuevo-item',
                      { 

                        'nombre_item': this.editedItem.nombre_item,
                        'descripcion_item': this.editedItem.descripcion_item,
                        'orden': this.editedItem.orden
                       },
                       config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                         this.ocultarLoading();

                          this.close()

                  }).catch(e=>{
                        console.log(e)
                  });
                  


                }else{
                    alert('debes completar todos los  campos');
                }


        }
        
      },
    },
  }
</script>