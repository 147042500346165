<template>

<v-card>
    <v-card-title>
       <span>Mantenimientos a Realizar</span>

        <v-divider  class="mx-4" inset vertical></v-divider>

         <v-flex md2 lg2 mx-4 pt-6>
                        <v-select v-model="id_pais_activo" dense 
                        outlined :items="PaisesArray" @change="listar()"  label="Pais"></v-select>
         </v-flex>

        <v-btn dark color="indigo" class="mx-5" @click="listar()"><v-icon>autorenew</v-icon></v-btn>

         <v-spacer></v-spacer>

         <v-text-field class="mx-5"
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
        ></v-text-field>


        <v-btn color="info" dark class="mb-2"  @click="dialog=true">
              <v-icon dark>add</v-icon>
            </v-btn>


    </v-card-title>


<v-layout>
  <v-data-table
    :headers="headers"
    :items="ArregloMantenimientos"
     sort-by="placa"
    :search="search"
    class="elevation-1"
  >
  
      
    <template v-slot:[`item.id_vehiculo_mant.nombre`]="{ item }">
       <span>{{item.id_vehiculo_mant.nombre}}</span>
       <img  :src="CambiarImgUrl(item.id_vehiculo_mant.icono)" v-bind:alt="item.icono" 
         style="width: 30px; height: 30px"/>
     </template>




    
      <template v-slot:[`item.id_vehiculo_flota`]="{ item }"> 
       <span>{{item.id_vehiculo_mant.flota}}</span>
     </template>


     <template v-slot:[`item.id_tipo_mant`]="{ item }"> 
       <span>{{item.id_tipo_mant.nombre_tipo}} / {{item.id_tipo_mant.cantidad_km}} km</span>
     </template>


     <template v-slot:[`item.fecha_anterior_mant`]="{ item }"> 
       <span>{{ConvertirFecha(item.fecha_anterior_mant)}} </span>
     </template>


     <template v-slot:[`item.status`]="{ item }"> 
       <v-btn v-if="item.status==1" color="info" small @click="RegistrarMantenimiento(item)">Activo <v-icon small>assignment_turned_in</v-icon></v-btn>
     </template>


     <template v-slot:[`item.remanente`]="{ item }"> 
      <v-chip class="ma-2 white--text" :color="ColorRemante(item)" @click="abrirDetalle(item)">
         {{item.remanente}}
    </v-chip>


     </template>

     

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
      <v-icon small @click="deleteItem(item)">delete</v-icon>
    </template>

    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>
  </v-data-table>

      <v-dialog v-model="dialog" max-width="810px">

          <v-card>


             <v-toolbar dark color="fondo1">
              <v-toolbar-title>
                 <span class="headline">{{ formTitle }} Agendado</span>
              </v-toolbar-title>
                 <v-spacer></v-spacer>
                 <v-icon @click="dialog=false" dark>close</v-icon>
            </v-toolbar>


            <v-card-text>
              <v-container>
                <v-row>

                   

                  <v-col cols="12" xl="5" lg="5" sm="5" md="5">
                        <v-select
                            v-model="editedItem.id_vehiculo_mant"
                            :items="UnidadesArray"
                            prepend-icon="time_to_leave"
                            label="Vehiculo"
                            :rules="[rules.required]"
                            :error-messages="errorMessages"
                             @change="LimpiarCampos()"
                          
                        ></v-select>
                  </v-col>



                  <v-col  cols="12" sm="4" md="3">
                  

                    <v-select
                            v-model="editedItem.id_tipo_mant"
                            :items="TipoArray"
                            label="Tipo mantenimiento"
                            :rules="[rules.required]"
                            :error-messages="errorMessages"
                            @change="CambiarTipoMantenimiento()"
                        ></v-select>

                  </v-col>



                  <v-col cols="12" sm="4" md="3">
                   

                     <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            v-model="editedItem.fecha_anterior_mant"
                            label="Fecha anterior Mant."
                            prepend-icon="calendar"
                            v-bind="attrs"
                            v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="editedItem.fecha_anterior_mant"
                            no-title
                            @input="menu1 = false"
                        ></v-date-picker>
                        </v-menu>

                  </v-col>

                  <v-col cols="12" sm="4" md="3">
                          <v-menu
                            ref="menu"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="time"
                            color="indigo"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="time"
                                label="Hora mant."
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-time-picker
                            color="indigo"
                            v-if="menu2"
                            v-model="time"
                            full-width
                            @click:minute="$refs.menu.save(time)"
                            ></v-time-picker>
                        </v-menu>

                  </v-col>

                  



                    <v-col cols="12" sm="3" md="3">
                    <v-text-field v-model="editedItem.kms_realizar" prepend-icon="straighten" label="kms_realizar" disabled></v-text-field>
                  </v-col>




                    <v-col cols="12" sm="3" md="3">
                    <v-btn color="info" dark @click="ConsultarOdometroAvl()" mt-3>
                      Odometros
                      <v-icon right dark>sync</v-icon>
                      </v-btn>
                  </v-col>

                  <v-col  cols="12" sm="6" md="3">
                    <v-text-field v-model="editedItem.odometro_anterior_mant" prepend-icon="speed" label="odometro_anterior_mant" :disabled="true"></v-text-field>
                  </v-col>


                  <v-col  cols="12" sm="6" md="3">
                    <v-text-field v-model="editedItem.odometro_actual" prepend-icon="speed" label="odometro_actual" :disabled="true"></v-text-field>
                  </v-col>

                  

                  <v-col  cols="12" sm="6" md="3">
                    <v-text-field v-model="editedItem.remanente" prepend-icon="running_with_errors" label="remanente" :disabled="true"></v-text-field>
                  </v-col>

                  <span class="caption"> mantenimiento segun orden:{{ mantenimiento_siguiente }}</span>

                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="success" dark @click="save">Guardar</v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Deseas deshabilitar Mantenimiento?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

      <v-dialog v-model="dialogRegistrar" persistent max-width="700px">

            <v-card>


                <v-toolbar dark color="fondo1">
                  <v-toolbar-title>
                    <span class="headline">Registrar Mantenimiento realizado</span>
                  </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-icon @click="dialogRegistrar=false" dark>close</v-icon>
                </v-toolbar>


                <v-card-text>
                    
                    <v-row>

                      <v-col  cols="12" sm="6" md="6">
                        <v-text-field v-model="itemRegistrar.unidad" prepend-icon="time_to_leave" label="vehiculo" :disabled="true"></v-text-field>
                      </v-col>


                      <v-col  cols="12" sm="6" md="6">
                        <v-text-field v-model="itemRegistrar.tipo_mantenimiento" prepend-icon="speed" label="Tipo Mantenimiento" :disabled="true"></v-text-field>
                      </v-col>

                      <v-col  cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-select
                                v-model="itemRegistrar.id_taller_mant"
                                :items="itemsTalleres"
                                label="Taller"
                                :rules="[rules.required]"
                                :error-messages="errorMessages"
                            ></v-select>

                            </v-col>

                      


                      <v-col  cols="12" sm="4" md="4" lg="4" xl="4">
                    

                        <v-menu
                            ref="menu3"
                            v-model="menu3"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="itemRegistrar.fecha_mant"
                                label="Fecha Inicio"
                                prepend-icon="today"
                                v-bind="attrs"
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="itemRegistrar.fecha_mant"
                                no-title
                                @input="menu3 = false"
                            ></v-date-picker>
                            </v-menu>
                      </v-col>


                        <v-col  cols="12" sm="4" md="4" lg="4" xl="4">
                    

                        <v-menu
                            ref="menu4"
                            v-model="menu4"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="itemRegistrar.fecha_fin"
                                label="Fecha Fin"
                                prepend-icon="today"
                                v-bind="attrs"
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="itemRegistrar.fecha_fin"
                                no-title
                                @input="menu4 = false"
                            ></v-date-picker>
                            </v-menu>
                      </v-col>


                      <v-col  cols="12" sm="6" md="3">
                        <v-text-field v-model="itemRegistrar.costo_mant" prepend-icon="paid" label="costo"></v-text-field>
                      </v-col>

                      <v-col  cols="12" sm="9" md="9">
                        <v-text-field v-model="itemRegistrar.observacion_mant" prepend-icon="description" label="Observacion"></v-text-field>
                      </v-col>



                    <v-col  cols="12" xs="12" sm="12" md="12" lg="12">

                      <v-list shaped style="height:200px;overflow-y:auto;width:80%;">
                        
                              <v-list-item-group multiple>
                                <template v-for="(item, i) in ItemArray">
                            
                            
                                  <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

                                  <v-list-item v-else :key="item.value" :value="item.value" active-class="deep-purple--text text--accent-4">
                              
                              
                                    <template v-slot:default="{ active }">
                                      <v-list-item-content>
                                        <v-list-item-title v-text="item.text"></v-list-item-title>
                                      </v-list-item-content>

                                      <v-list-item-action>
                                        <v-checkbox
                                          :id="item.value"
                                          :input-value="active"
                                          v-model="ChekedItems"
                                          :value="item.value"
                                          color="deep-purple accent-4"
                                        ></v-checkbox>
                                      </v-list-item-action>
                                    </template>
                                  </v-list-item>
                              
                                </template>
                              </v-list-item-group>
                              <span v-if="ItemArray.length < 1"> no hay items asociados</span>
                              
                            
                            </v-list>

                    </v-col>


                    </v-row>
                </v-card-text>

            <v-card-actions>
              <span>**fecha fin podra cambiar estado de vehiculo a taller</span>
              <v-spacer></v-spacer>
              <v-btn text color="blue" @click="dialogRegistrar=false">Cancelar</v-btn>
              <v-btn dark color="primary" @click="GuardarRegistroMantenimiento()">Guardar</v-btn>
            
            </v-card-actions>

            </v-card>

      </v-dialog>


      <v-dialog v-model="dialogDetalle" max-width="500px">

        <v-card>

              <v-toolbar dark color="info">
                  <v-toolbar-title>
                    <span class="headline">Detalle mantenimientos</span>
                  </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-icon @click="dialogDetalle=false" dark>close</v-icon>
                </v-toolbar>

            <v-card-text>

              <v-simple-table light> 
                  <template v-slot:default>
                  <tbody>

                    <tr >
                    <td><v-icon small left>time_to_leave</v-icon>Unidad</td>
                    <td>{{detalle.unidad}}</td>
                  </tr>

                  <tr >
                    <td><v-icon small left>commute</v-icon>Flota</td>
                    <td>{{detalle.flota}}</td>
                  </tr>


                  <tr >
                    <td><v-icon small left>list_alt</v-icon>Tipo mantenimiento</td>
                    <td>{{detalle.tipo_mant}}</td>
                  </tr>


                  <tr >
                    <td><v-icon small left>speed</v-icon>odometro anterior</td>
                    <td>{{detalle.odom_anterior}} <span class="caption indigo--text">{{detalle.tipo_odom}}</span></td>
                  </tr>
                  <tr>
                    <td><v-icon small left>speed</v-icon>odometro actual</td>
                    <td>{{detalle.odom_actual}} <span class="caption indigo--text">{{detalle.tipo_odom}}</span></td>
                  </tr>

                  <tr>
                    <td><v-icon small left>straighten</v-icon>Recorrido</td>
                    <td>{{detalle.recorrido}}</td>
                  </tr>

                  <tr>
                    <td><v-icon small left>flag</v-icon>KM realizar</td>
                    <td>{{detalle.km_realizar}}</td>
                  </tr>


                    <tr>
                    <td> <v-icon small left>forward</v-icon>Remanente</td>
                    <td>{{detalle.remanente}}</td>
                  </tr>

                  <tr>
                    <td>%  Recorrido</td>
                    <td>{{detalle.porcentaje}}</td>
                  </tr>


                </tbody>
                </template>

                </v-simple-table>


            </v-card-text>
          </v-card>

      </v-dialog>

</v-layout>


   
    
    </v-card>
</template>



<script>


import axios from 'axios';
import { mapMutations, mapState } from "vuex";

  export default {
    data: () => ({
      search:'',
      Tipos:[],
      TipoArray:[],
      ArrayVehiculos:[],
      UnidadesArray:[],
      nombre_flota:'',
     
      ArregloMantenimientos:[],
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Vehiculo', value: 'id_vehiculo_mant.nombre' },
        { text: 'Placa', value: 'id_vehiculo_mant.placa' },
        { text: 'Tipo Mantenimiento', value: 'id_tipo_mant' },
        { text: 'Fecha Anterior Mant.', value: 'fecha_anterior_mant' },
        { text: 'Odometro Anterior Mant.', value: 'odometro_anterior_mant' },

        { text: 'Km realizar.', value: 'kms_realizar'},
        { text: 'Remanente', value: 'remanente' },
        { text: 'Estatus', value: 'status' },

        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    
      editedIndex: -1,
      editedItem: {
        id_vehiculo_mant:'',
        id_tipo_mant: 0,
        id_taller_mant: 0,
        fecha_anterior_mant:new Date().toISOString().substr(0, 10),
        odometro_anterior_mant: 0,
        kms_realizar: 0,
        remanente:0,
        odometro_actual:0,
        status:1,
        observacion_mant:'',
        fecha_mant:'',
        fecha_fin:'',
        costo_mant:'',
        id_pais_mant:'',
        activo:1
      },
      defaultItem: {
        id_vehiculo_mant:'',
        id_tipo_mant: 0,
        id_taller_mant: 0,
        fecha_anterior_mant:new Date().toISOString().substr(0, 10),
        odometro_anterior_mant: 0,
        kms_realizar: 0,
        remanente:0,
        odometro_actual:0,
        status:1,
        observacion_mant:'',
        fecha_mant:'',
        fecha_fin:'',
        costo_mant:'',
        d_pais_mant:'',
        activo:1
      },

       errorMessages:'',
       rules: {
          numerico:(value)=>value > 0 || "valor incorrecto",
          min3Chars: (value) => value.length >= 3 || "Min. 3 characters",
          required: (value) => !!value || "campo Requerido.",
          email: (value) => {
                              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                              return pattern.test(value) || "Invalid e-mail.";
                           },
       
       }, 
       menu1:false,
       date: new Date().toISOString().substr(0, 10),
      

       menu2:false,
       time: null ,
       id_avl:'',

       menu3:false,
       menu4:false,

       ArrayTalleres:[],
       itemsTalleres:[],
       dialogRegistrar:false,


       itemRegistrar:{
         unidad:'',
         tipo_mantenimiento:'',
         id_taller_mant:'',
         observacion_mant:'',
         fecha_mant:'',
         fecha_fin:'',
         costo_mant:'',
       },

       dialogDetalle:false,
       detalle:{},

       mantenimiento_siguiente:'',
       ArrayItemMantenimento:[],
       ItemArray:[],
       ChekedItems:[],

       PaisesArray:[],
       id_pais_activo:'',


    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Mantenimiento' : 'Edicion Mantenimiento'
      },

        ...mapState(['usuario', 'token']),

    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize();
       this.listarItems();
     
    },

    methods: {

       ...mapMutations(['mostrarLoading','ocultarLoading']),

      initialize () {
        this.ArregloMantenimientos = [];
        this.listarPaises();


        let hora=new Date().getHours();
        if(hora <10){hora='0'+hora;}
        
        let minutos=new Date().getMinutes();
        if(minutos <10){minutos='0'+minutos;}

        this.time=hora+":"+minutos;
      },


      listarPaises() {

         let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

         let RespuestaPaises=[];

          axios.get('paises',  config
          ).then(function(response){
             console.log(response);
             RespuestaPaises=response.data;

             RespuestaPaises.map(function(x){
                  me.PaisesArray.push({text:x.nombre_pais, value:x._id})
                });

             me.id_pais_activo=me.PaisesArray[0].value;

             me.ocultarLoading();

              me.listar();
              me.listarVehiculos();
              me.listarTipos();
            
              
          }).catch(function(error){
            console.log(error);
          });

         


        },




      listar(){

              this.ArregloMantenimientos=[];

               this.mostrarLoading({titulo:'Accediendo a datos'});
              let config={headers:{token:this.token}};
              let me=this;
              axios.get(`mantenimientos/${this.id_pais_activo}`,
                  config
                  ).then(function(response){
                      console.log(response);

                      let respuesta=response.data;

                      respuesta.map(function(x){

                        if( x.id_vehiculo_mant  && x.id_vehiculo_mant.nombre  && x.id_vehiculo_mant.nombre!=null){
                          me.ArregloMantenimientos.push(x);
                        }
                        
                      })


                     
                     me.ocultarLoading();
                  }).catch(function(error){ console.log(error); });

        },

      listarVehiculos(){

                    this.ArrayVehiculos=[];
                 
                 

                      this.mostrarLoading({titulo:'Accediendo a datos'});
                      let config={headers:{token:this.token}};
                      let me=this;
                    axios.get(`vehiculos/${this.id_pais_activo}`,
                        config
                        ).then(function(response){
                            console.log(response);
                            me.ArrayVehiculos=response.data;

                            me.ArrayVehiculos.map(function(x){
                                        me.UnidadesArray.push({'text':x.nombre, 'value':x._id});
                            });

                          me.ocultarLoading();
                        }).catch(function(error){ console.log(error); });

      },

      listarTipos(){

              this.TipoArray=[];
              this.Tipos=[];

              this.mostrarLoading({titulo:'Accediendo a datos'});
              let config={headers:{token:this.token}};
              let me=this;
              axios.get(`tipos/${this.id_pais_activo}`,
                  config
                  ).then(function(response){
                      console.log(response);

                      me.Tipos=response.data;

                            me.Tipos.map(function(x){
                              if(x.grupo=='Agendado'){
                                me.TipoArray.push({'text':x.nombre_tipo, 'value':x._id});
                               }
                            });

                            me.listarTalleres();
    
                     me.ocultarLoading();
                  }).catch(function(error){ console.log(error); });
      },

  

      listarTalleres(){
            this.ArrayTalleres=[];
            this.itemsTalleres=[];

              this.mostrarLoading({titulo:'Accediendo a datos'});
              let config={headers:{token:this.token}};
              let me=this;
              axios.get(`talleres/${this.id_pais_activo}`,
                config
                  ).then(function(response){
                      console.log(response);
                      me.ArrayTalleres=response.data;

                      me.ArrayTalleres.map(function(x){
                        me.itemsTalleres.push({text:x.nombre_taller, value:x._id});
                      });
                   me.ocultarLoading();
                  }).catch(function(error){ console.log(error); });

      },

      listarItems(){
         this.ArrayItemMantenimento=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`items`,  config
                    ).then(function(response){
                      
                        me.ArrayItemMantenimento=response.data;  
                        me.ArrayItemMantenimento.map(function(x){
                          me.ItemArray.push({text:x.nombre_item, value:x._id});

                        });

                        me.ocultarLoading();

                          console.log(me.ArrayItemMantenimento);
                    }).catch(function(error){ console.log(error); });
      },





      editItem (item) {
        this.editedIndex = this.ArregloMantenimientos.indexOf(item)
        this.editedItem = Object.assign({}, item)

        
          //setamos valor de vehiculo en edicion
         this.editedItem.id_vehiculo_mant=item.id_vehiculo_mant._id;

         //convertimos las fecha
         this.editedItem.fecha_anterior_mant=new Date(item.fecha_anterior_mant).toISOString().substr(0, 10);


        //seteamo valor tipo mantenimiento
         this.editedItem.id_tipo_mant=item.id_tipo_mant._id;


         //mantenimiento_siguiente sistema debe indicar cual debe seguir

         let numero_actual=item.id_tipo_mant.orden;
           numero_actual++;

           let me=this;

           this.Tipos.map(function(x){

                if(x.orden==numero_actual){
                   me.mantenimiento_siguiente=x.nombre_tipo;
                }
            
               });

        

        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.ArregloMantenimientos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
       // this.ArregloMantenimientos.splice(this.editedIndex, 1)

                this.mostrarLoading({titulo:'Accediendo a datos'});

                let id=this.editedItem._id;
                 let config={headers:{token:this.token}};

                    axios.put(`mantenimientos/${id}`,  {'activo':0}, config
                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });

        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
            //edicion de mantenimiento
            

           if((this.editedItem.odometro_anterior_mant!='')&&(this.editedItem.remanente!='')&&(this.editedItem.kms_realizar!='')
            &&(this.editedItem.id_vehiculo_mant!='')){



               if( 0 < parseFloat(this.editedItem.remanente)){

              alert('ya se excedieron los km recorridos a este mantenimiento');

             }else{



                    let config={headers:{token:this.token}};
                     this.mostrarLoading({titulo:'actualizando'});
                    let id=this.editedItem._id;

                    axios.put(`mantenimientos/${id}`,
                      { 
                        'id_vehiculo_mant': this.editedItem.id_vehiculo_mant,
                        'id_tipo_mant': this.editedItem.id_tipo_mant,
                        'fecha_anterior_mant': new Date(this.editedItem.fecha_anterior_mant+" "+this.time+":00"),
                        'odometro_anterior_mant':this.editedItem.odometro_anterior_mant,
                        'kms_realizar':this.editedItem.kms_realizar,
                        'remanente':this.editedItem.remanente,
                        'clasificacion':'Agendado',
                        'status':1,
                        'activo':this.editedItem.activo,
                       },
                       config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                        this.close()
                        this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });

             }

        }else{
          alert('faltan campos');
        }
        
        
        } else {
         // this.ArregloMantenimientos.push(this.editedItem)
         //nuevo registro


        if((this.editedItem.odometro_anterior_mant!='')&&(this.editedItem.remanente!='')&&(this.editedItem.kms_realizar!='')
            &&(this.editedItem.id_vehiculo_mant!='')){



            if( 0 < parseFloat(this.editedItem.remanente)){


              alert('ya se excedieron los km recorridos a este mantenimiento');

             }else{

                    let config={headers:{token:this.token}};
                    this.mostrarLoading({titulo:'actualizando'});
                  
                    axios.post('nuevo-mantenimiento',
                      { 
                        'id_pais_mant': this.id_pais_activo,
                        'id_vehiculo_mant': this.editedItem.id_vehiculo_mant,
                        'id_tipo_mant': this.editedItem.id_tipo_mant,
                        'fecha_anterior_mant': new Date(this.editedItem.fecha_anterior_mant+" "+this.time+":00"),
                        'odometro_anterior_mant':this.editedItem.odometro_anterior_mant,
                        'kms_realizar':this.editedItem.kms_realizar,
                        'remanente':this.editedItem.remanente,
                        'clasificacion':'Agendado',
                        'status':1,
                        'activo':this.editedItem.activo,
                       },
                       config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                        this.close()
                        this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });


            }

        }else{
          alert('faltan campos');
        }

        }
       
      },


      ConsultarOdometroAvl(){

        if(this.editedItem.id_vehiculo_mant!=''){

            if(this.editedItem.id_tipo_mant!=''){

                this.id_avl='';

                let tipo_odometro_considerar='';
                let odom_actual_hw='';
                let odom_actual_sw='';

                  let me=this;
               
               this.ArrayVehiculos.map(function(x){
                    if(x._id==me.editedItem.id_vehiculo_mant){
                       me.id_avl=x.id_avl; 
                       tipo_odometro_considerar=x.tipo_odo_activo;
                       odom_actual_hw=x.odometro_hw;
                       odom_actual_sw=x.odometro_sw;
                    }
            });
          
                    let config={headers:{token:this.token}};
                    this.mostrarLoading({titulo:'actualizando'});
            
                    axios.post(`BuscarOdometro/${this.id_pais_activo}`,
                      { 

                        'fecha': this.editedItem.fecha_anterior_mant,
                        'hora': this.time,
                        'id_avl': this.id_avl
                       },
                      config

                        ).then(response=>{
                      
                         console.log(response);

                         let respuesta=response.data;

                        if((respuesta.odometro_hw)||(respuesta.odometro_sw)){

                            if(tipo_odometro_considerar=='Hardware'){
                                  me.editedItem.odometro_anterior_mant=respuesta.odometro_hw;

                                  let recorrido=parseFloat(odom_actual_hw)-parseFloat(respuesta.odometro_hw);
                                  let remanente=recorrido-parseFloat(me.editedItem.kms_realizar);
                                  me.editedItem.remanente=parseFloat(remanente).toFixed(2);
                                  me.editedItem.odometro_actual=odom_actual_hw;
                             
                            }else if(tipo_odometro_considerar=='Software'){
                                  me.editedItem.odometro_anterior_mant=respuesta.odometro_sw;
                              
                                  let recorrido=parseFloat(odom_actual_sw)-parseFloat(respuesta.odometro_sw);
                                  let remanente=recorrido-parseFloat(me.editedItem.kms_realizar);
                                  me.editedItem.remanente=parseFloat(remanente).toFixed(2);
                                  me.editedItem.odometro_actual=odom_actual_sw;

                            }
                                
                        }else{
                            alert('no tiene datos AVL');
                        }
                        
                        
                      this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });

          }else{
            alert('seleccione tipo de mantenimiento');
          }


    }else{
      alert('selecciona vehiculo');
    }




      },

      CambiarTipoMantenimiento(){

        this.editedItem.odometro_anterior_mant='';
        this.editedItem.remanente='';
        this.editedItem.kms_realizar='';

        let me=this;

        me.Tipos.map(function(x){
              if(x._id==me.editedItem.id_tipo_mant){
                me.editedItem.kms_realizar=x.cantidad_km;
              }
                                
           });



      },
      LimpiarCampos(){
        this.editedItem.odometro_anterior_mant='';
        this.editedItem.remanente='';
        this.editedItem.odometro_actual='';

        let me=this;




       //indicamos el mantenimiernto que deberia continuar

        console.log('id_ vehiculo:'+this.editedItem.id_vehiculo_mant);
        let id_tipo_man='';


         me.ArrayVehiculos.map(function(y){
                          if(me.editedItem.id_vehiculo_mant==y._id){

                                if(y.ultimo_mant){
                                    id_tipo_man=y.ultimo_mant;
                                    console.log('ultimo mantenineito:'+id_tipo_man)   
                                }
                                     
                               }
                         });



        if(id_tipo_man!=''){

         let numero_actual='';

           this.Tipos.map(function(x){

                if(x._id==id_tipo_man){
                  numero_actual=x.orden;
                  numero_actual++;
                }

                if((numero_actual!='')&&(x.orden==numero_actual)){
                   me.mantenimiento_siguiente=x.nombre_tipo;
                   me.editedItem.id_tipo_mant=x._id;
                }
            
               });

      }


      },

      ConvertirFecha(fecha_parametro){

        let texto=new Date(fecha_parametro).toLocaleDateString()+" "+new Date(fecha_parametro).toLocaleTimeString();

        return(texto);

      },

      RegistrarMantenimiento(item){

        this.itemRegistrar={};

        this.dialogRegistrar=true;

        this.ChekedItems=[];



        this.itemRegistrar={
                            _id:item._id,
                            unidad:item.id_vehiculo_mant.nombre,
                            id_vehiculo:item.id_vehiculo_mant._id,
                            tipo_mantenimiento:item.id_tipo_mant.nombre_tipo,
                            id_tipo_mantenimiento:item.id_tipo_mant._id,
                            id_taller_mant:'',
                            observacion_mant:'',
                            fecha_mant:'',
                            fecha_fin:'',
                            costo_mant:''
                          };

        console.log(item);

      },

      GuardarRegistroMantenimiento(){

        if(this.itemRegistrar){
            if((this.itemRegistrar.id_taller_mant!='')&&(this.itemRegistrar.fecha_mant!='')&&(this.ChekedItems.length > 0)&&(this.itemRegistrar.costo_mant > 0)){

              
                    let config={headers:{token:this.token}};
                    this.mostrarLoading({titulo:'actualizando'});


                    
                    console.log('items:'+this.ChekedItems);
                    var separa=this.ChekedItems;
                    separa=separa.toString();
                    separa=separa.split(",");

                    let ArrayEnviarItems=[];

                  for(let i=0; i < separa.length; i++){
                    console.log('datos:ref_item:'+separa[i]);
                    ArrayEnviarItems.push({ref_item:separa[i]});
                  }

                  let fecha_fin='';

                  let insertar=false;

                  console.log('fecha fin:'+this.itemRegistrar.fecha_fin);

                  if(this.itemRegistrar.fecha_fin!=''){

                    console.log('datos fecha fin:'+new Date(this.itemRegistrar.fecha_fin+"T00:00:00.000-06:00") );
                    console.log('datos hoy:'+ new Date(this.CalcularFechaHoy()+"T00:00:00.000-06:00"));
                           
                            if(new Date(this.itemRegistrar.fecha_fin+"T00:00:00.000-06:00") >= new Date(this.CalcularFechaHoy()+"T00:00:00.000-06:00") ){
                              this.ActualizarEstadoVehiculo(this.itemRegistrar.id_vehiculo);

                            }

                             fecha_fin=this.itemRegistrar.fecha_fin+"T00:00:00.000-06:00";


                         insertar=true;
                  }else{

                    if((this.itemRegistrar.fecha_fin==null)||(this.itemRegistrar.fecha_fin=='')){

                        if (confirm('mantto no tiene fecha final, vehiculo apartir de hoy pasara al estado de taller?')) {
                          // Save it!
                           this.ActualizarEstadoVehiculo(this.itemRegistrar.id_vehiculo);
                           insertar=true;
                        } else {
                          // Do nothing!
                            console.log('nada que hacer');
                        }

                       
                    }

                  }



                  if(insertar==true){

                            //status 2 es mantenimiento realizado
                              let id=this.itemRegistrar._id;

                              axios.put(`mantenimientos/${id}`,
                                { 
                                  'id_taller_mant': this.itemRegistrar.id_taller_mant,
                                  'status': 2, 
                                  'observacion_mant': this.itemRegistrar.observacion_mant,
                                  'fecha_mant': this.itemRegistrar.fecha_mant+"T00:00:00.000-06:00",
                                  'fecha_fin':fecha_fin,
                                  'costo_mant':this.itemRegistrar.costo_mant,
                                  'items_realizados':ArrayEnviarItems
                                },
                              config

                                  ).then(response=>{
                                
                                  console.log(response);

                                    this.dialogRegistrar=false;
                                  
                                  this.listar();
                                  this.close()
                                  this.ocultarLoading();

                            }).catch(e=>{
                                  console.log(e)
                            });


                            //actualizar a vehiuclo que este fue el ultimo mantenimeinto realizado


                            
                            this.mostrarLoading({titulo:'actualizando'});
                              let id_veh=this.itemRegistrar.id_vehiculo;

                              axios.put(`vehiculo/${id_veh}`,
                                { 'ultimo_mant': this.itemRegistrar.id_tipo_mantenimiento},
                              config

                                  ).then(response=>{
                                
                                  console.log(response);
                                  
                                  this.listar();
                                  this.ocultarLoading();
                                  this.close()

                            }).catch(e=>{
                                  console.log(e)
                            });

            }//fin si insertar es vacio





            }else{
              alert('faltan campos que completar');
            }
        }


      },

      abrirDetalle(item){

        this.detalle={};
        this.dialogDetalle=true;

        let odom_actual=0;
        let tipo_odom='';

        if(item.id_vehiculo_mant.tipo_odo_activo=='Software'){

             odom_actual=item.id_vehiculo_mant.odometro_sw;
             tipo_odom='Software';

        }else if(item.id_vehiculo_mant.tipo_odo_activo=='Hardware'){

              odom_actual=item.id_vehiculo_mant.odometro_hw;
               tipo_odom='Hardware';
        }


        let recorrido=parseFloat(odom_actual)-parseFloat(item.odometro_anterior_mant);

        let porcentaje=0;

        if(recorrido > 0){

            porcentaje=recorrido/parseFloat(item.kms_realizar);
            porcentaje=porcentaje*100;

        }



        this.detalle={
                      'unidad':item.id_vehiculo_mant.nombre,
                      'flota':item.id_vehiculo_mant.flota,
                      'tipo_mant':item.id_tipo_mant.nombre_tipo,
                      'odom_anterior':item.odometro_anterior_mant,
                      'odom_actual':odom_actual,
                      'tipo_odom':tipo_odom,
                      'recorrido':recorrido,
                      'km_realizar':item.kms_realizar,
                      'remanente':item.remanente,
                      'porcentaje':porcentaje
                      };

      },

      CambiarImgUrl(picture){
            picture=picture.substring(0,picture.length - 5);
            if(picture.slice(-1)!='/'){
                    picture=picture.substring(0,picture.length - 1);
                }
                return (picture+'/E.png');
      },


      getDate(evaluar) {
      let date = new Date(evaluar),
      year = date.getFullYear(),
      month = (date.getMonth() + 1).toString(),
      formatedMonth = (month.length === 1) ? ("0" + month) : month,
      day = date.getDate().toString(),
      formatedDay = (day.length === 1) ? ("0" + day) : day,
      hour = date.getHours().toString(),
      formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
      minute = date.getMinutes().toString(),
      formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
      second = date.getSeconds().toString(),
      formatedSecond = (second.length === 1) ? ("0" + second) : second;
    return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
    },

    ActualizarEstadoVehiculo(_id){

          let config={headers:{token:this.token}};

               this.mostrarLoading({titulo:'Accediendo a datos'});

               console.log('actualizamos el estado a taller');

                    axios.put(`vehiculo/${_id}`,
                      {'estado':'Taller'},
                      config
                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });


    },
     CalcularFechaHoy(){
          let fecha=new Date();
          let mes=fecha.getMonth()+1;
          if(mes<10)mes='0'+mes;
          let dia=fecha.getDate();
          if(dia<10)dia='0'+dia;
          let textofecha=fecha.getFullYear()+'-'+mes+'-'+dia;

          return(textofecha);
    },
    ColorRemante(item){

      console.log("datos item");
      console.log(item);

      let color="blue";

      if(item.remanente < 0){
        color="blue";
        let kms_realizar_item=parseFloat(item.kms_realizar);
        let temp_remanente=parseFloat(item.remanente) * -1;
        console.log('calulo:'+temp_remanente+'/'+kms_realizar_item);
        let procentaje_re=temp_remanente/kms_realizar_item;
        procentaje_re=procentaje_re * 100;
        console.log('%:'+procentaje_re);

        let porcentaje_color=parseFloat(item.id_tipo_mant.porcentaje_alerta);
        

        if(procentaje_re <= porcentaje_color){
          color="orange";
        }

      }

      if(item.remanente > 0){
        color="red";
      }

          return(color);

    },







    },
  }
</script>