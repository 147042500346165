<template>

<v-card>
    <v-card-title>
       <span> Talleres</span>

        <v-divider  class="mx-4" inset vertical></v-divider>


         <v-flex md2 lg2 mx-4 pt-6>
                        <v-select v-model="id_pais_activo" dense 
                        outlined :items="PaisesArray" @change="listar()"  label="Pais"></v-select>
         </v-flex>

        
         <v-btn dark color="indigo" class="mx-5" @click="listar()"><v-icon>autorenew</v-icon></v-btn>

          <v-spacer></v-spacer>

        <v-text-field class="mx-5"
        v-model="search"
        append-icon="search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>


      <v-btn color="info" dark class="mb-2"  @click="dialog=true">
              <v-icon dark>add</v-icon>
            </v-btn>

 </v-card-title>

  <v-data-table
    :headers="headers"
    :items="ArrayTalleres"
    :search="search"
    sort-by="nombre_taller"
    class="elevation-1"
>
 
       
     <template v-slot:[`item.activo`]="{ item }">
        <v-icon>
           {{ item.activo==1 ? "done" : "label_off" }}
        </v-icon>
     </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
      <v-icon small @click="deleteItem(item)">delete</v-icon>
    </template>

    <template v-slot:no-data>
      <v-btn color="info" @click="initialize">Reset</v-btn>
    </template>
  </v-data-table>

        <v-dialog v-model="dialog" max-width="600px" >
          <v-card>

             <v-toolbar dark color="fondo1">
              <v-toolbar-title>
                 <span class="headline">{{ formTitle }}</span>
              </v-toolbar-title>
                 <v-spacer></v-spacer>
                 <v-icon @click="dialog=false" dark>close</v-icon>

            </v-toolbar>


            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field prepend-icon="business" v-model="editedItem.nombre_taller" label="Nombre Taller"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field prepend-icon="pin" v-model="editedItem.nit_ruc" label="NIT"></v-text-field>
                  </v-col>

                  
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field prepend-icon="contact_page" v-model="editedItem.contactos" label="Contactos"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field prepend-icon="phone" v-model="editedItem.telefono" label="Telefono"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="12">
                    <v-text-field v-model="editedItem.descripcion_taller" label="Descripcion"></v-text-field>
                  </v-col>

                   <v-col cols="12" sm="6" md="4">

                    <input type="radio" :value="1" v-model="editedItem.activo"> Activo
                    <input type="radio" :value="0" v-model="editedItem.activo"> Inactivo
 
                  </v-col>
                  
                 
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text  @click="close">Cancelar</v-btn>
              <v-btn color="primary" dark @click="save">Guardar</v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Deseas deshabilitar taller?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
</v-card>

</template>


<script>

import axios from 'axios';

import { mapMutations, mapState } from "vuex";

  export default {
    data: () => ({
      ArrayTalleres:[],
      dialog: false,
      dialogDelete: false,
      search:'',
      headers: [
        { text: 'Nombre taller', value: 'nombre_taller' },
        { text: 'NIT', value: 'nit_ruc' },
        { text: 'Descripcion', value: 'descripcion_taller' },
        { text: 'Contactos', value: 'contactos' },
        { text: 'Telefono', value: 'telefono' },
        { text: 'Activo', value: 'activo' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        nombre_taller: '',
        nit_ruc: '',
        descripcion_taller: '',
        contactos: '',
        telefono: '',
        id_pais_taller:'',
        activo:1
      },
      defaultItem: {
        nombre_taller: '',
        nit_ruc: '',
        descripcion_taller: '',
        contactos: '',
        telefono: '',
        id_pais_taller:'',
        activo:1
      },

      id_pais_activo:'',
      PaisesArray:[],

     
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo taller' : 'Edicion'
      },

         ...mapState(['usuario', 'token']),
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {

       ...mapMutations(['mostrarLoading','ocultarLoading']),

      initialize () {
        this.ArrayTalleres = [];
        this.listarPaises();
      },


      
      listarPaises () {

         let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

         let RespuestaPaises=[];

          axios.get('paises',  config
          ).then(function(response){
             console.log(response);
             RespuestaPaises=response.data;

             RespuestaPaises.map(function(x){
                  me.PaisesArray.push({text:x.nombre_pais, value:x._id})
                });

             me.id_pais_activo=me.PaisesArray[0].value;

             me.ocultarLoading();

             me.listar();
            
              
          }).catch(function(error){
            console.log(error);
          });

         


           },



       listar(){

              this.ArrayTalleres=[];

              this.mostrarLoading({titulo:'Accediendo a datos'});
              let config={headers:{token:this.token}};
              let me=this;
              axios.get(`talleres/${this.id_pais_activo}`,
                  config
                  ).then(function(response){
                      console.log(response);
                      me.ArrayTalleres=response.data;
                      me.ocultarLoading();
                  }).catch(function(error){ console.log(error); });

        },


      editItem (item) {
        this.editedIndex = this.ArrayTalleres.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.ArrayTalleres.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      
         this.mostrarLoading({titulo:'Accediendo a datos'});
         let config={headers:{token:this.token}};
          let id=this.editedItem._id;

                    axios.put(`taller/${id}`,
                      {'activo':0},
                      config
                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });
                  
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {

          //edicion de taller
        
                    let config={headers:{token:this.token}};
                    this.mostrarLoading({titulo:'actualizando'});
                    let id=this.editedItem._id;

                    axios.put(`taller/${id}`,
                      { 

                        'nombre_taller': this.editedItem.nombre_taller,
                        'nit_ruc': this.editedItem.nit_ruc,
                        'descripcion_taller': this.editedItem.descripcion_taller,
                        'contactos': this.editedItem.contactos,
                        'telefono':this.editedItem.telefono,
                        'activo':this.activo
                       },
                       config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });





        } else {

      
        //guardamos taller

                   let config={headers:{token:this.token}};
                   this.mostrarLoading({titulo:'actualizando'});
                   
                    axios.post('nuevo-taller',
                      { 

                        'nombre_taller': this.editedItem.nombre_taller,
                        'nit_ruc': this.editedItem.nit_ruc,
                        'descripcion_taller': this.editedItem.descripcion_taller,
                        'contactos': this.editedItem.contactos,
                        'telefono':this.editedItem.telefono,
                        'id_pais_taller':this.id_pais_activo
                       },
                       config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });

        
        }
        this.close()
      }
    }
  }
</script>